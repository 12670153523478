.footerBtns {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  background: #fff;
  height: 50px;
  display: flex;
  align-items: center;

  .btnItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 33.333333%;
    display: flex;
    height: 100%;
    // padding: 11px 0 10px;

    i {
      font-size: 20px;
      color: #2e384e;
      line-height: 1;
      margin-bottom: 4px;
    }

    span {
      font-size: 12px;
      font-weight: 500;
      color: #2e384e;
      line-height: 1;
    }
  }

  .active {
    i {
      color: #ff3e5f;
    }

    span {
      color: #ff3e5f;
    }
  }
}

.modal-content {
  border-radius: 24px;
  text-align: center;
  background-color: #fff;
}
.modalFooter {
  border-top: 1px solid #eaedf1;
  div + div {
    border-left: 1px solid #eaedf1;
  }
}
