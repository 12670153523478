.gift-img {
  position: fixed;
  line-height: 1;
  top: 18px;
  // right: 110px;
  z-index: 50;
  margin-right: 10px;
  animation: swing 1.2s linear infinite;
}

@keyframes swing {
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}

.tip-top {
  min-width: 100px;
  padding: 6px 10px;
  position: absolute;
  z-index: 50;
  top: 50px;
  // right: 14%;
  background: rgba(1, 5, 23, 0.8);
  box-shadow: 0rpx 8px 13px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  color: #fff;

  .text {
    font-size: 14px;
    font-weight: 400;
  }
}

.tip-top:after {
  content: '';
  display: block;
  border-width: 7px;
  position: absolute;
  top: -13px;
  right: 13%;
  border-style: solid dashed dashed solid;
  border-color: transparent transparent rgba(1, 5, 23, 0.8) transparent;
  font-size: 0;
  line-height: 0;
}

.gift-dialog {
  position: fixed;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .container {
    width: 320px;
    height: 337px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .btn {
    position: absolute;
    bottom: -22%;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 48px;
    background: #ff3e5f;
    border-radius: 24px;
    font-size: 18px;
    font-family: Poppins-Black, Poppins;
    font-weight: 900;
    color: #ffffff;
  }

  .close {
    position: absolute;
    top: -60px;
    right: 0;
    width: 32px;
    height: 32px;
    border: 2px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    color: #fff;
  }
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: #010517;
  color: #fff;
  border-radius: 5px;
  z-index: 9999999;
  font-size: 14px;
}
