//这个函数的作用是对于一些需要动态从某一个分辨率屏幕到另一个分辨率的变化
//$max (需要变化的最大值), $min(需要变化的最小值),
//$maxScreen(需要变化的最大值时屏幕的大小), $minscreen(需要变化的最大值时屏幕的大小), $diff(max-min)
@function selfAdapt1($max, $min, $diff) {
  // @return $max - (($maxScreen - 100vw) / (($maxScreen - $minScreen) / ($diff)));
  @return #{"calc(#{$max} - ((1024Px - 100vw) / (256 / #{$diff})))"};
}
@media screen and (max-width: 1366px) and (min-width: 1024px) {
  .nav-wrapper {
    width: calc(1120px - ((1110px - 100vw) / (256 / 158)));
    /*1166px -> 1120px padding 100px -> 80*/
  }
}
.zalo {
  position: absolute;
  // left: 280px;
  // top: 0;
  width: 280px;
  height: 316px;
  background: #ffffff;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .top {
    height: 224px;
    border-radius: 8px 8px 0 0;
    background: #f7f8fb;

    // padding-top: 32px;
  }

  .bottom {
    height: 92px;

    .titleZalo {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      // font-weight: 600;
      color: #2e384e;
      font-weight: bold;
      text-align: center;
      margin-top: 16px;
    }

    .title2Zalo {
      font-size: 12px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #969ba6;
      margin-top: 0px;
      text-align: center;
    }
  }
}

.nav {
  // max-width: 1840Px;
  // padding: 0 40px;
  height: 70px;
  position: fixed;
  // background: linear-gradient(132deg, #D4F4FF 0%, #FFF5D9 62%, #FFCDBB 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // overflow: hidden;
  width: 100%;
  z-index: 50;
  transition: all 200ms ease-in-out;

  .logo {
    width: 149px;
  }

  a {
    // margin: 0 16Px;
    cursor: pointer;
    font-size: 14px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #191919;

    :hover {
      opacity: none !important;
    }
  }

  .left {
    display: flex;
    align-items: center;

    div {
      // height: 100%;

      .menus {
        margin-left: 24px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        height: 100%;

        .nohover {
          &:hover {
            opacity: 1 !important;
            color: unset;
          }
        }

        a {
          display: flex;
          align-items: center;
          color: #0e1527;
          // padding: 0 36px;
          padding-left: 36px;

          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
          }
        }

        //   a {
        //     display: flex;
        //     margin: 0 16px;
        //     cursor: pointer;
        //     font-size: 14px;
        //     font-family: PingFangSC-Regular, PingFang SC;
        //     font-weight: 400;
        //     color: #191919;
        //   }

        .menusItem {
          position: relative;
          z-index: 999;

          .hoverMenu {
            display: none;
            // display: block !important;
            position: fixed;
            top: 70px;
            background: white;
            left: 0;
            right: 0;
            max-height: 261px;
            border-top: 1px solid #f0f0f2;
            // box-shadow: inset 0px 1px 0px 0px #E5E5E5;
            box-shadow: 0 7px 6px -8px rgba(0, 0, 0, 0.1);
            // box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);

            .onecate {
              height: 48px;
              border-radius: 4px;
              font-size: 15px;
              // font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #191919;
              opacity: 0.5;
              text-align: left;
              padding-left: 12px;
            }

            .twocate {
              height: 48px;
              border-radius: 8px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2e384e;
              text-align: left;
              padding-left: 12px;

              &:hover {
                background: #f6f6f6;
                opacity: none !important;
              }
            }
          }

          &:hover {
            a {
              color: #ff3e5f;
            }

            .iconArrow {
              transform: rotate(180deg);
            }

            .hoverMenu {
              display: block !important;
            }
          }

          :hover {
          }

          .iconArrow {
            transition: all 0.3s ease-in-out;
          }

          .menusDropdown {
            display: none;
            position: absolute;
            bottom: 0;
            transform: translateY(100%);
            background: #ffffff;
            box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            border: 1px solid #f0f0f0;
            padding: 10px 8px;

            .dropdownList {
              .dropdownListItem {
                margin: 0 !important;
                width: 180px;
                height: 40px;
                padding-left: 23px;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 400;
                line-height: 40px;

                &:hover {
                  background: #f6f6f6;
                }
              }
            }
          }

          &:hover {
            .menusDropdown {
              display: block !important;
            }

            .hoverBox {
              display: block !important;
            }
          }

          .hoverBox {
            display: none;
            position: absolute;
            top: 46px;
            right: -200%;
            background: #ffffff;
            box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            font-size: 12px;
            font-weight: 600;
            color: #2e384e;
            cursor: default;
            padding: 24px;

            &:hover {
              opacity: 1 !important;
            }
            .group_wrap {
              min-width: 572px;
              height: 178px;
              background: linear-gradient(125deg, #fff4d7 0%, #ffdfdf 100%);
              border-radius: 8px;
              padding: 20px 25px;
            }
            .join {
              min-width: 113px;
              height: 26px;
              border-radius: 6px;
              border: 1px solid #d9d9d9;
              color: #191919;
              padding: 0 12px;
              display: flex;
              align-items: center;
              margin-top: 12px;
              font-size: 12px;
              &:hover {
                background: #eff1f4;
                color: #191919;
              }
            }
          }
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .login {
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 400;
      color: #191919;
      cursor: pointer;
      margin-right: 15px;

      // width: 68px;
      padding: 0 8px;
      height: 26px;
      border-radius: 13px;
      border: 1px solid rgba(151, 151, 151, 0.3);
      text-align: center;
      line-height: 26px;
    }

    .start {
      display: block;
      width: 110px;
      height: 36px;
      background: #ff3e5f;
      // box-shadow: 0 3PX 6PX 0PX #f2b8c5;
      border-radius: 18px;
      margin-left: 24px;
      margin-right: 24px;
      text-align: center;
      line-height: 36px;
      vertical-align: middle;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }

    .start:hover {
      opacity: 0.8;
    }

    .start:active {
      opacity: 0.8;
    }

    .mobileOpen {
      display: none;
      cursor: pointer;
    }
  }
  .normal-menu-item {
    cursor: pointer;
    display: flex;
    .normalRotate {
      // 行内元素 hover最好改行内块元素
      display: inline-block;
      transition: all 0.3s ease-out;
    }
    .item-title {
      display: inline-block;
      z-index: 6;
    }
    &:hover {
      .item-title {
        color: #ff3e5f;
      }
      .children-box {
        display: inline-block;
      }
      .normalRotate {
        transform: rotate(-180deg);
        color: #ff3e5f;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100px;
      top: 0px;
      left: 0px;
    }
    .children-box {
      display: none;
      height: auto;
      padding: 8px;
      top: 48px;
      left: 24px;
      box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.08);
      z-index: 5;
    }
    .child-item {
      min-width: 130px;
      font-size: 16px;
      color: #0a122c;
      line-height: 24px;
      padding: 8px 16px !important;
      // padding: 0px;
      border-radius: 8px;
      &:hover {
        background: #f8f8f8;
      }
    }
  }
}

.mobileNav {
  display: none;
  overflow: auto;
  position: fixed;
  background-color: #fff;
  width: 85vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 51;

  .mobileTop {
    padding: 10px 16px;
    height: 70px;
    // margin-top: 44px;
    // margin-bottom: 21px;
    // padding: 24px 24px 24px 30px;
  }

  .mobileClose {
    float: right;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .mobileMenus {
    position: relative;

    .mobileLink {
      padding: 12px 0 12px 30px;

      a {
        color: #0e1527;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        // padding: 0 16px;
      }

      .sub {
        // margin-left: 20Px;

        .mobileSubLink {
          padding: 19px 0 19px 30px;
        }

        :first-child {
          padding-top: 38px;
        }
      }
    }

    .chooseLang {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;

      svg {
        width: 24px;
        height: 24px;
        margin: 0 24px;
      }
    }

    .subMobileLink {
      display: none;
    }
  }

  .mobileStart {
    display: block;
    position: absolute;
    // left: 50%;
    margin-left: calc((100% - 260px) / 2);
    text-align: center;
    width: 260px;
    height: 56px;
    background: #ff3e5f;
    box-shadow: 0px 10px 14px -4px #f2b8c5;
    border-radius: 18px;
    text-align: center;
    line-height: 56px;
    vertical-align: middle;
    font-size: 14aPx;
    color: #fff;
    cursor: pointer;
    bottom: 35px;
  }

  .mobileStart:hover {
    opacity: 0.8;
  }

  .mobileStart:active {
    opacity: 0.8;
  }

  .mobileMenuList {
    position: relative;
    background: #fff;

    .menuItem {
      overflow: hidden;
      background: #fff;

      .menuHeader {
        height: 48px;
        padding: 14px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        background: #fff;
      }

      .menuHeaderLeft {
        display: flex;
        align-items: center;

        .iconfont {
          color: #0e1527;
        }

        a {
          font-size: 16px;
          font-weight: 600;
          color: #0e1527;
          line-height: 22px;
          margin-left: 16px;
        }
      }

      .menuHeaderRight {
        i {
          font-size: 20px;
        }
      }

      .menuChild {
        transform: translateY(0);
        opacity: 1;
        height: 100%;
        overflow: hidden;
        transition: all 200ms linear;
      }

      .menuChildItem {
        height: 48px;
        width: 100%;
        padding: 13px 0;
        padding-left: 48px;
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        color: #0e1527;
        line-height: 22px;
        z-index: -1;
      }

      .menuChildHidden {
        transform: translateY(-40%);
        opacity: 0;
        height: 0;
      }
    }
  }

  .userInfo {
    border-top: 1px solid #f0f0f0;
    margin: 43px 0;
    display: flex;
    padding: 48px 16px 0;

    .userInfo_name {
      font-size: 14px;
      font-weight: 600;
      color: #0e1527;
      line-height: 20px;
      margin-bottom: 1px;
    }

    .userInfo_tip {
      font-size: 12px;
      font-weight: 400;
      color: #708096;
      line-height: 17px;
      margin-bottom: 0;
    }
  }
}
.contrySelect {
  position: relative;
  .selectContent {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &:hover {
    .selectList {
      display: block;
    }
  }
  .selectList {
    display: none;
    position: absolute;
    right: 50%;
    top: 60px;
    padding: 8px;
    width: 200px;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    transform: translateX(50%);
    .selectItem {
      display: flex;
      align-items: center;
      padding: 14px 16px;
      border-radius: 4px;
      cursor: pointer;
      .selectText {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #191919;
        line-height: 21px;
      }
      &:hover {
        background: #f7f8fb;
      }
    }
  }
}

@media screen and (max-width: 1310px) {
  .nav {
    .left {
      .menus {
        a {
          padding-left: 16px;
        }
      }
    }
  }
}

// @media screen and (max-width: 1130px) {
//   .nav {
//     .right {
//       .login {
//         margin-right: 15px;

//         width: 62px;
//         height: 26px;
//         border-radius: 13px;
//         opacity: 0.69;
//         border: 1px solid #979797;

//         a {
//           font-size: 12px;
//           font-family: PingFangSC-Semibold, PingFang SC;
//           font-weight: 600;
//           color: #191919;
//         }

//         @apply flex items-center justify-center;

//       }

//       .start {
//         display: none;
//       }
//     }
//   }
// }

// @media screen and (min-width: 1180px) {
//   .nav {
//     .left {
//       .menus {
//         display: inline-flex;
//         align-items: center;

//         // margin-left: 44Px;
//         a {
//           margin: 0 16px;
//           cursor: pointer;
//           font-size: 14px;
//           font-family: PingFangSC-Regular, PingFang SC;
//           font-weight: 400;
//           color: #191919;
//           transition: all 100ms ease-in-out;
//           display: inline-flex;
//           align-items: center;
//         }

//         svg {
//           display: inline;
//         }

//         path {
//           fill: #191919;
//         }

//         a:hover {
//           transition: all 100ms ease-in-out;
//           // text-decoration: underline;
//           color: #ff3e5f;

//           path {
//             fill: #ff3e5f;
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1180Px) and (min-width: 901Px) {
//   .nav {
//     padding: 0 30Px;
//     // min-width: 850Px;

//     .left {
//       .menus {
//         margin-left: 24Px;

//         a {
//           margin: 0 12Px;
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 1024px) and (min-width: 375px) {
  .nav {
    padding: 0 24px;

    .left {
      .menus {
        // display: none;
      }
    }

    .right {
      .mobileOpen {
        display: block;
        width: 24px;
        height: 24px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .contrySelect {
    .selectList {
      display: block;
      right: 0;
      top: 45px;
      transform: none;
    }
  }
}

@media screen and (max-width: 500px) and (min-width: 375px) {
  .nav {
    padding: 0 24px;

    .left {
      .menus {
        display: none;
      }
    }

    .right {
      // .login,
      .start {
        display: none !important;
      }

      .mobileOpen {
        display: block;
        width: 24px;
        height: 24px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .nav {
    width: 100%;
    padding: 0 20px;

    .left {
      .menus {
        display: none;
      }
    }

    .right {
      // .login,
      .start {
        display: none !important;
      }

      .mobileOpen {
        display: block;
        width: 24px;
        height: 24px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.langSelect {
  background: none;
  width: auto;
  font-size: 14px;
  line-height: 21px;
}
