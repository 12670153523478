.questionnaire {
  &-wrapper {
    padding: 24px 16px;
    background: url("https://mcdn.shoplus.net/custom/images/question-bg.png") no-repeat top;
    background-color: #fff;
    background-size: 100% auto;
    //width: 316px;
    min-height: 209px;
    position: relative;
    box-sizing: border-box;
    border-radius: 24px;
  }
  &-image {
    width: 140px;
    height: 141px;
    position: absolute;
    right: 0;
    top: -46px;
    &_success {
      width: 150px;
      height: 150px;
      margin-top: -95px;
    }
  }
  &-title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: rgba(0,0,0,0.85);
    line-height: 24px;
    margin-bottom: 17px;
  }
  &-btnWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
  &-button {
    box-shadow: 0px 13px 18px -5px rgba(246, 69, 110, 0.34);
    border-radius: 24px;
    background: #ff3e5f;
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    width: 200px;
    height: 40rpx;
    line-height: 40px;
    text-align: center;
  }
  &-skip {
    color: #b8c0cb;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
  }
  &-progress {
    transform: skew(-25deg);
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FF3E5F;
    line-height: 24px;
    background: rgba(255, 62, 95, 0.1);
    border-radius: 6px;
    width: 118px;
    margin-bottom: 47px;
    text-align: center;
  }
  &-optionWrap {
    max-height: 180px;
    overflow-y: scroll;
    :global {
      .ant-radio-group {
        display: flex;
        flex-direction: column;
      }
      .ant-radio-wrapper {
        line-height: 32px;
        font-size: 16px;
      }
    }
    &-fixed {
      position: relative;
      &:after {
        position: absolute;
        display: block;
        content: "";
        bottom: 0;
        width: 100%;
        height: 62px;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
      }
    }
  }
  &-successText {
    font-weight: 600;
    color: #2e384e;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    margin-top: 10px;
  }
}
.disabled {
  opacity: 0.5;
}
